import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AuthWrapper from "../../../components/authWrapper";
import TextInput from "../../../components/TextInput";
import Styles from "./CreateNewPassword.module.scss";
import Button from "../../../components/appButton";
import { useNavigate } from "react-router-dom";
import { getItemToLocalStorage } from "../../../utilities/helpers/appHealper";
import useToast from "../../../utilities/hooks/useToast";
import services from "../../../controllers";
const CreateNewPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const toast = useToast();
  const [otp, setOtp] = useState("");
  const newPasswordValidationSchema = Yup?.object()?.shape({
    password: Yup.string().required("Required"),
    rpassword: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (!getItemToLocalStorage("otp")) {
      navigate("/");
      return;
    }
    setOtp(getItemToLocalStorage("otp"));
  }, []);

  const newPasswordFormik = useFormik({
    validationSchema: newPasswordValidationSchema,
    initialValues: {
      password: "",
      rpassword: "",
    },
    onSubmit: async (values: any) => {
      if (values?.password !== values?.rpassword) {
        return toast.error("Password does not match.");
      }
      setLoader(true);
      let data = {
        password: values?.password,
        code: otp,
      };

      const resendCode = new services().patchService(
        "auth/resetPassword",
        data,
      );
      resendCode
        .then((res) => {
          setLoader(false);
          if (res?.status === "success") {
            toast.success(res?.message);
            localStorage.clear();
            navigate("/");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("an error occurred, try again later.");
        });
    },
  });
  return (
    <div className={Styles.wrapper}>
      <AuthWrapper>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__title}>Reset Password</div>
          <div className={Styles.wrapper__subtitle}>
            Kindly create a new password for your account
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={newPasswordFormik?.values?.password}
              name={"password"}
              onChange={newPasswordFormik.handleChange}
              placeholder="Enter your Password"
              type="password"
              error={
                newPasswordFormik.submitCount > 0 &&
                newPasswordFormik.errors.password
              }
            />
          </div>
          <div style={{ marginBottom: "64px" }}>
            <TextInput
              value={newPasswordFormik?.values?.rpassword}
              name={"rpassword"}
              onChange={newPasswordFormik.handleChange}
              placeholder="Confirm Password"
              type="password"
              error={
                newPasswordFormik.submitCount > 0 &&
                newPasswordFormik.errors.rpassword
              }
            />
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Change Password"
              onClick={newPasswordFormik?.handleSubmit}
              loading={loader}
            />
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default CreateNewPassword;
