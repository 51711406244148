import React from "react";

const SongUploadIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="26" fill="#FFD5D2" />
      <path
        d="M68.6667 28.3933C68.3514 28.1805 67.9888 28.0479 67.6106 28.0073C67.2324 27.9666 66.85 28.019 66.4967 28.16L43.1633 37.4933C42.7328 37.6647 42.3631 37.9604 42.1013 38.3427C41.8396 38.725 41.6975 39.1766 41.6933 39.64V59.3333C40.6119 58.9673 39.4747 58.7936 38.3333 58.82C33.83 58.82 30.3066 61.7133 30.3066 65.4233C30.3066 69.1333 33.83 72.0266 38.3333 72.0266C39.6741 72.0827 41.0089 71.8176 42.2265 71.2535C43.4441 70.6893 44.5092 69.8424 45.3333 68.7833C45.6673 68.5801 45.9449 68.2963 46.1406 67.9579C46.3363 67.6196 46.4438 67.2374 46.4533 66.8466V50.1866L65.12 43.0233V54.69C64.0105 54.3051 62.8407 54.1233 61.6667 54.1533C57.1633 54.1533 53.64 57.0466 53.64 60.7566C53.64 64.4666 57.1633 67.36 61.6667 67.36C63.0074 67.416 64.3422 67.151 65.5598 66.5868C66.7774 66.0227 67.8426 65.1757 68.6667 64.1166C69.0006 63.9135 69.2782 63.6297 69.4739 63.2913C69.6696 62.9529 69.7771 62.5708 69.7867 62.18V30.3066C69.7733 29.9198 69.6639 29.5424 69.4684 29.2084C69.2729 28.8744 68.9974 28.5943 68.6667 28.3933Z"
        fill="#E05F3F"
      />
    </svg>
  );
};

export default SongUploadIcon;
