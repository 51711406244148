import React from "react";
import Styles from "./AppUpload.module.scss";
interface IAppUploadComponent {
  id: string;
  className?: any;
  handleChange: any;
  isMutiple?: boolean;
  accept?: any;
  icon: any;
  title?: string;
  subTitle?: string;
  imageUrl?: string;
  iconBackgroundClassName?: any;
  backgroundHeight?: string;
  backgroundWidth?: string;
  backgroundBorderRadius?: string;
  disabled?: boolean;
}
const AppUploadComponent = ({
  id,
  className,
  handleChange,
  isMutiple,
  accept,
  icon,
  subTitle,
  title,
  imageUrl,
  iconBackgroundClassName,
  backgroundHeight,
  backgroundWidth,
  backgroundBorderRadius,
  disabled,
}: IAppUploadComponent) => {
  return (
    <div
      className={Styles.wrapper || className}
      style={{
        backgroundImage: imageUrl ? `url(${imageUrl})` : "",
        height: backgroundHeight,
        width: backgroundWidth,
        borderRadius: backgroundBorderRadius,

        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <input
        disabled={disabled}
        type={"file"}
        id={id || "upload-file"}
        className={Styles.wrapper__fileInput}
        onChange={handleChange}
        multiple={isMutiple}
        accept={accept}
      />
      <label
        className={Styles.wrapper__inputLable}
        htmlFor={id || "upload-file"}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        <div className={Styles.wrapper__inputLableIcon}>
          <div
            className={
              iconBackgroundClassName || Styles.wrapper__inputIconCircle
            }
          >
            {icon}
          </div>
        </div>
        {title && <div className={Styles.wrapper__inputTitle}>{title}</div>}

        {subTitle && (
          <div className={Styles.wrapper__inputSubTitle}>{subTitle}</div>
        )}
      </label>
    </div>
  );
};

export default AppUploadComponent;
