import React from "react";
import AvatarImage from "../../assets/images/user-avatar.png";
import Styles from "./AppAvater.module.scss";
interface IAppAvater {
  width: string;
  height: string;
  avatarUrl?: string;
}
const AppAvater = ({ width, height, avatarUrl }: IAppAvater) => {
  return (
    <div
      className={Styles.wrapper}
      style={{
        width,
        height,
        borderRadius: "100%",
      }}
    >
      <img
        src={avatarUrl || AvatarImage}
        alt=""
        style={{ width, height, borderRadius: "100%" }}
      />
    </div>
  );
};

export default AppAvater;
