import React from "react";
interface ICancelIcon {
  onClick?: any;
}

const CancelIcon = ({ onClick }: ICancelIcon) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <path
        d="M10.1484 21.8516L21.8516 10.1484"
        stroke="#E9E9E9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1484 10.1484L21.8516 21.8516"
        stroke="#E9E9E9"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelIcon;
