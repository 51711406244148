import React from "react";
import Loading from "../Loading";
import Styles from "./AppLoader.module.scss";
const AppLoader = () => {
  return (
    <div className={Styles?.wrapper}>
      <Loading />
    </div>
  );
};

export default AppLoader;
