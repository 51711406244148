import React from "react";
import Styles from "./button.module.scss";
interface IButton {
  title: string;
  className?: any;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  padding?: string;
  fontSize?: string;
  backgroundColor?: string;
  fontFamily?: string;
  height?: string;
  color?: string;
  icon?: any;
  border?: string;
}
const Button = ({
  title,
  className,
  onClick,
  disabled,
  loading,
  width,
  padding,
  fontSize,
  backgroundColor,
  fontFamily,
  height,
  color,
  icon,
  border,
}: IButton) => {
  return (
    <button
      className={`${Styles.button} ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      style={{
        width,
        padding,
        fontSize,
        backgroundColor,
        fontFamily,
        height,
        color,
        border,
      }}
    >
      {icon && <span className={Styles.button__rightIcon}>{icon}</span>}

      {loading ? "Loading..." : title}
    </button>
  );
};

export default Button;
