import React from "react";
interface ISettingsIcon {
  color?: string;
}
const SettingsIcon = ({ color }: ISettingsIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_431)">
        <path
          d="M12.9532 8.65325C12.9799 8.43992 12.9999 8.22658 12.9999 7.99992C12.9999 7.77325 12.9799 7.55992 12.9532 7.34659L14.3599 6.24658C14.4866 6.14658 14.5199 5.96658 14.4399 5.81992L13.1066 3.51325C13.0266 3.36659 12.8466 3.31325 12.6999 3.36659L11.0399 4.03325C10.6932 3.76659 10.3199 3.54659 9.91323 3.37992L9.65989 1.61325C9.63989 1.45325 9.49989 1.33325 9.33323 1.33325H6.66656C6.49989 1.33325 6.35989 1.45325 6.33989 1.61325L6.08656 3.37992C5.67989 3.54659 5.30656 3.77325 4.95989 4.03325L3.29989 3.36659C3.14656 3.30659 2.97323 3.36659 2.89323 3.51325L1.55989 5.81992C1.47323 5.96658 1.51323 6.14658 1.63989 6.24658L3.04656 7.34659C3.01989 7.55992 2.99989 7.77992 2.99989 7.99992C2.99989 8.21992 3.01989 8.43992 3.04656 8.65325L1.63989 9.75325C1.51323 9.85325 1.47989 10.0333 1.55989 10.1799L2.89323 12.4866C2.97323 12.6333 3.15323 12.6866 3.29989 12.6333L4.95989 11.9666C5.30656 12.2333 5.67989 12.4533 6.08656 12.6199L6.33989 14.3866C6.35989 14.5466 6.49989 14.6666 6.66656 14.6666H9.33323C9.49989 14.6666 9.63989 14.5466 9.65989 14.3866L9.91323 12.6199C10.3199 12.4533 10.6932 12.2266 11.0399 11.9666L12.6999 12.6333C12.8532 12.6933 13.0266 12.6333 13.1066 12.4866L14.4399 10.1799C14.5199 10.0333 14.4866 9.85325 14.3599 9.75325L12.9532 8.65325ZM7.99989 10.3333C6.71323 10.3333 5.66656 9.28658 5.66656 7.99992C5.66656 6.71325 6.71323 5.66658 7.99989 5.66658C9.28656 5.66658 10.3332 6.71325 10.3332 7.99992C10.3332 9.28658 9.28656 10.3333 7.99989 10.3333Z"
          fill={color || "#AAAAAA"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3_431">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SettingsIcon;
