export const setItemToLocalStorage = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};
export const getItemToLocalStorage = (key: string) => {
  if (typeof window !== "undefined") {
    const retrievedObject: any = localStorage?.getItem(key) || null;
    return JSON?.parse(retrievedObject);
  }
};

export const setItemToSessionStorage = (key: string, value: any) => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

export const getItemFromSessionStorage = (key: string) => {
  if (typeof window !== "undefined") {
    const retrievedObject: any = sessionStorage?.getItem(key) || null;
    return JSON.parse(retrievedObject);
  }
};

export const FormatToK = (num: number) => {
  if (Math.abs(num) > 999) {
    let getNewAmount = Math.sign(num) * (Math.abs(num) / 1000);
    return getNewAmount?.toFixed(0) + "k";
  } else {
    return Math.sign(num) * Math.abs(num);
  }
};

export const minutsDiffrence = (timeValue: any, durationValue: any) => {
  if (timeValue && durationValue) {
    const [hours1, minutes1] = timeValue.split(":").map(Number);
    const [hours2, minutes2] = durationValue.split(":").map(Number);

    // Convert the times to minutes for comparison
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;
    if (totalMinutes1 < totalMinutes2) {
      // If start time is after end time, return false (validation error)
      return true;
    } else {
      return false;
    }
  }
};
