import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import UploadIcon from "../../assets/svgs/UploadIcon";
import AppModal from "../../components/appModal";
import AppUploadComponent from "../../components/appUpload";
import TextInput from "../../components/TextInput";
import { useAppDispatch, useAppSelector } from "../../redux/reducers/hooks";
import {
  setIsRefetchMusic,
  setIsShowUploadModal,
} from "../../redux/reducers/uploadModalSlice";
import Styles from "./UploadSoundModal.module.scss";
import CheckInput from "../../components/checkInput";
import Button from "../../components/appButton";
import useToast from "../../utilities/hooks/useToast";
import axios from "axios";
import SongUploadIcon from "../../assets/svgs/SongUploadIcon";
import { Link } from "react-router-dom";
import { setMusicEditDetails } from "../../redux/reducers/editSongSlice";
const UploadSongModal = () => {
  const toast = useToast();

  const [acceptTerms, setAcceptTerms]: any = useState(false);
  const [loader, setLoader]: any = useState(false);
  const [songCover, setSongCover]: any = useState(null);
  const [imageCover, setImageCover]: any = useState(null);
  const { userProfile } = useAppSelector((state) => state?.userProfileDetails);
  const { musicEditDetails } = useAppSelector(
    (state) => state?.editMusicDetails,
  );
  const dispatch = useAppDispatch();
  const uploadSongValidationSchema = Yup?.object()?.shape({
    title: Yup.string().required("Required"),
    artiste: Yup.string().required("Required"),
    spotifyLink: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter a valid URL,eg., https://example.com",
      )
      .optional(),
  });

  const getError = (message: string) => {
    return toast.error(message);
  };

  useEffect(() => {
    if (musicEditDetails) {
      setAcceptTerms(true);
    }
  }, [musicEditDetails]);

  const uploadSong = (values: any) => {
    if (!imageCover) {
      getError("Image cover is required.");
      return;
    }
    if (!acceptTerms) {
      getError("you must accept to the terms and conditions.");
      return;
    }

    if (!songCover) {
      getError("Song cover is required.");
      return;
    }

    if (songCover?.type !== "audio/mpeg") {
      getError("File not supported.");
      return;
    }

    setLoader(true);
    const formData: any = new FormData();
    formData.append("owner[0]", userProfile?._id);
    formData.append("audio_file[0]", songCover);
    formData.append("artiste[0]", values?.artiste);
    formData.append("title[0]", values?.title);
    formData.append("spotify_link[0]", values?.spotifyLink);
    formData.append("terms_condition[0]", true);
    formData.append("cover_image[0]", imageCover?.file);

    // url: `${process.env.REACT_APP_AIBASEURL}audiofiles/?model_type=os`,

    axios({
      method: "post",
      url: `${process.env.REACT_APP_AIBASEURL}audiofiles/?model_type=os`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setLoader(false);
        if (res?.data[0]?.audio) {
          toast.success("Music uploaded successfuly.");
          dispatch(setIsRefetchMusic(true));
          dispatch(setIsShowUploadModal(false));
          dispatch(setMusicEditDetails(null));
        } else {
          toast.error(
            res?.data[0]?.errors?.cover_image ||
              "an error occurred, try again later.",
          );
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("an error occurred, try again later.");
      });
  };

  const editSong = (values: any) => {
    if (!acceptTerms) {
      getError("you must accept to the terms and conditions.");
      return;
    }

    setLoader(true);
    const formData: any = new FormData();
    // formData.append("owner[0]", userProfile?._id);
    // formData.append("audio_file[0]", songCover);
    if (imageCover?.file) {
      formData.append("cover_image", imageCover?.file);
    }

    formData.append("artiste", values?.artiste);
    formData.append("title", values?.title);
    formData.append("spotify_link", values?.spotifyLink);
    formData.append("terms_condition", true);

    // url: `${process.env.REACT_APP_AIBASEURL}audiofiles/?model_type=os`,

    axios({
      method: "put",
      url: `${process.env.REACT_APP_AIBASEURL}audiofiles/${musicEditDetails?.id}/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res: any) => {
        setLoader(false);

        if (res?.status === 200) {
          toast.success("Music successfuly updated.");
          dispatch(setIsRefetchMusic(true));
          dispatch(setIsShowUploadModal(false));
          dispatch(setMusicEditDetails(null));
        } else {
          toast.error(
            res?.detail ||
              res?.errors?.spotify_link[0] ||
              "an error occurred, try again later.",
          );
        }
      })
      .catch((error) => {
        setLoader(false);

        toast.error(
          error?.response?.data?.detail ||
            "an error occurred, try again later.",
        );
      });
  };

  const uploadSongFormik = useFormik({
    validationSchema: uploadSongValidationSchema,
    initialValues: {
      title: musicEditDetails?.title || "",
      artiste: musicEditDetails?.artiste || "",
      spotifyLink: musicEditDetails?.spotifyLink || "",
    },
    onSubmit: async (values: any) => {
      // let itemsLabel = [
      //   "owner",
      //   "audio_file",
      //   "artiste",
      //   "title",
      //   "cover_image",
      //   "terms_condition",
      // ];

      // let data: any = [
      //   {
      //     owner: userProfile?._id,
      //     audio_file: songCover,
      //     artiste: "taoheed",
      //     title: values?.title,
      //     cover_image: imageCover,
      //     terms_condition: true,
      //   },
      // ];

      // let data1Dict2: any = {};

      // // Iterate over each item in the data1 array
      // data.forEach((item: any, index: any) => {
      //   // For each key in the item, add or update the entry in the dictionary
      //   itemsLabel.forEach((key) => {
      //     let id = `${key}[${index}]`;
      //     // Check if the id already exists in the dictionary
      //     if (data1Dict2.hasOwnProperty(id)) {
      //       // If it exists, push the new value to the array
      //       data1Dict2[id].push(item[key]);
      //     } else {
      //       // If it doesn't exist, create a new array with the value
      //       data1Dict2[id] = [item[key]];
      //     }
      //   });
      // });

      // console.log(data);

      //     let data: any = {
      //   owner: userProfile?._id,
      //   audio_file: songCover,
      //   artiste: "taoheed",
      //   title: values?.title,
      //   cover_image: imageCover,
      //   terms_condition: true,
      // },

      if (musicEditDetails) {
        editSong(values);
        return;
      }
      uploadSong(values);
    },
  });

  return (
    <AppModal
      handleClickAway={() => {
        dispatch(setIsShowUploadModal(false));
        dispatch(setMusicEditDetails(null));
      }}
      title="Upload a Song"
    >
      <div className={Styles?.wrapper}>
        <div className={Styles?.wrapper__songCover}>
          <div className={Styles?.wrapper__songTitle}>Song Cover</div>
          <div className={Styles?.wrapper__songcoverUpload}>
            <AppUploadComponent
              imageUrl={imageCover?.localUrl || musicEditDetails?.coverImage}
              icon={<UploadIcon />}
              title="Upload Song Cover"
              subTitle="(Upload in PNG or JPEG format)"
              id="image-cover"
              accept={"image/png, image/jpeg"}
              handleChange={(data: any) =>
                setImageCover({
                  file: data?.target.files[0],
                  localUrl: URL.createObjectURL(data?.target?.files[0]),
                })
              }
            />
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={uploadSongFormik?.values?.title}
              name="title"
              title="Song Title"
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter the title of the song"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.title
              }
            />
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={uploadSongFormik?.values?.artiste}
              name="artiste"
              title="Artiste"
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter artiste name"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.artiste
              }
            />
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={uploadSongFormik?.values?.spotifyLink}
              name="spotifyLink"
              title="Spotify link"
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter spotify link"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.spotifyLink
              }
            />
          </div>
          <div className={Styles?.wrapper__songTitle}>Song File</div>
          <div
            className={`${musicEditDetails && Styles?.wrapper__songFileUploadDisabled} ${Styles?.wrapper__songFileUpload}`}
          >
            <AppUploadComponent
              disabled={musicEditDetails}
              icon={
                songCover || musicEditDetails ? (
                  <SongUploadIcon />
                ) : (
                  <UploadIcon />
                )
              }
              title={songCover ? "Song File" : "Upload Song File"}
              subTitle="(Upload in Mp3 format)"
              accept={".mp3,audio/*"}
              id="song-cover"
              handleChange={(data: any) => setSongCover(data?.target.files[0])}
            />
          </div>

          <div className={Styles.wrapper__checkContainer}>
            <CheckInput
              isChecked={acceptTerms}
              onChange={() => {
                setAcceptTerms(!acceptTerms);
              }}
              label={
                <div className={Styles.wrapper__check}>
                  I have read the{" "}
                  <span>
                    <Link
                      to="https://audoji-frontend-staging.vercel.app/terms-and-conditions"
                      target={"_blank"}
                    >
                      Terms and Conditions
                    </Link>
                  </span>{" "}
                  and I warrant that I have all necessary rights, permissions
                  and consents to upload and share the content.
                </div>
              }
            />
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Upload Song"
              onClick={uploadSongFormik?.handleSubmit}
              loading={loader}
            />
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default UploadSongModal;
