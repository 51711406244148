import React from "react";

const EyeOpen = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 5.83344C6.95961 5.83344 4.36773 7.63063 3.30054 10.1619C3.27929 10.2123 3.24831 10.258 3.20937 10.2965C3.17044 10.3349 3.12432 10.3653 3.07364 10.3859C3.02296 10.4065 2.96871 10.417 2.914 10.4166C2.85929 10.4163 2.80518 10.4052 2.75476 10.3839C2.70435 10.3626 2.65861 10.3317 2.62017 10.2927C2.58173 10.2538 2.55133 10.2077 2.53071 10.157C2.5101 10.1063 2.49966 10.0521 2.50001 9.99736C2.50035 9.94265 2.51147 9.88854 2.53273 9.83812C3.73179 6.99437 6.62867 5 9.99992 5C13.3712 5 16.268 6.99437 17.4671 9.83812C17.4884 9.88854 17.4995 9.94265 17.4998 9.99736C17.5002 10.0521 17.4897 10.1063 17.4691 10.157C17.4485 10.2077 17.4181 10.2538 17.3797 10.2927C17.3412 10.3317 17.2955 10.3626 17.2451 10.3839C17.1947 10.4052 17.1406 10.4163 17.0858 10.4166C17.0311 10.417 16.9769 10.4065 16.9262 10.3859C16.8755 10.3653 16.8294 10.3349 16.7905 10.2965C16.7515 10.258 16.7206 10.2123 16.6993 10.1619C15.6321 7.63063 13.0399 5.83344 9.99992 5.83344Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 14.1662C6.95961 14.1662 4.36773 12.369 3.30054 9.83777C3.27929 9.78736 3.24831 9.74162 3.20937 9.70318C3.17044 9.66474 3.12432 9.63434 3.07364 9.61372C3.02296 9.5931 2.96871 9.58267 2.914 9.58302C2.85929 9.58336 2.80518 9.59448 2.75476 9.61574C2.70435 9.637 2.65861 9.66798 2.62017 9.70691C2.58173 9.74584 2.55133 9.79197 2.53071 9.84265C2.5101 9.89333 2.49966 9.94757 2.50001 10.0023C2.50035 10.057 2.51147 10.1111 2.53273 10.1615C3.73179 13.0053 6.62867 14.9996 9.99992 14.9996C13.3712 14.9996 16.268 13.0053 17.4671 10.1615C17.4884 10.1111 17.4995 10.057 17.4998 10.0023C17.5002 9.94757 17.4897 9.89333 17.4691 9.84265C17.4485 9.79197 17.4181 9.74584 17.3797 9.70691C17.3412 9.66798 17.2955 9.637 17.2451 9.61574C17.1947 9.59448 17.1406 9.58336 17.0858 9.58302C17.0311 9.58267 16.9769 9.5931 16.9262 9.61372C16.8755 9.63434 16.8294 9.66474 16.7905 9.70318C16.7515 9.74162 16.7206 9.78736 16.6993 9.83777C15.6321 12.369 13.0399 14.1662 9.99992 14.1662Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99957 7.91645C9.72384 7.91307 9.45019 7.96446 9.19447 8.06764C8.93875 8.17082 8.70606 8.32373 8.50989 8.51752C8.31372 8.71131 8.15796 8.94211 8.05166 9.19654C7.94536 9.45097 7.89062 9.72398 7.89062 9.99973C7.89062 10.2755 7.94536 10.5485 8.05166 10.8029C8.15796 11.0573 8.31372 11.2881 8.50989 11.4819C8.70606 11.6757 8.93875 11.8286 9.19447 11.9318C9.45019 12.035 9.72384 12.0864 9.99957 12.083C10.5477 12.0763 11.071 11.8539 11.4563 11.4639C11.8415 11.0739 12.0575 10.5479 12.0575 9.99973C12.0575 9.45158 11.8415 8.92552 11.4563 8.53556C11.071 8.1456 10.5477 7.92316 9.99957 7.91645ZM7.08301 9.99957C7.08301 9.61656 7.15845 9.2373 7.30502 8.88345C7.45159 8.5296 7.66642 8.20808 7.93725 7.93725C8.20808 7.66642 8.5296 7.45159 8.88345 7.30502C9.2373 7.15845 9.61656 7.08301 9.99957 7.08301C10.3826 7.08301 10.7618 7.15845 11.1157 7.30502C11.4695 7.45159 11.7911 7.66642 12.0619 7.93725C12.3327 8.20808 12.5476 8.5296 12.6941 8.88345C12.8407 9.2373 12.9161 9.61656 12.9161 9.99957C12.9161 10.7731 12.6089 11.5149 12.0619 12.0619C11.5149 12.6089 10.7731 12.9161 9.99957 12.9161C9.22605 12.9161 8.48421 12.6089 7.93725 12.0619C7.39029 11.5149 7.08301 10.7731 7.08301 9.99957Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default EyeOpen;
