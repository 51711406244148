import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Styles from "./OtpInput.module.scss";
interface IOtpInputComp {
  updateOtp: any;
}
const OtpInputComp = ({ updateOtp }: IOtpInputComp) => {
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp) {
      updateOtp(otp);
    }
  }, [otp]);
  return (
    <div className={Styles.wrapper}>
      {" "}
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span className={Styles.wrapper__divider} />}
        renderInput={(props) => <input {...props} />}
        inputStyle={{
          width: "40px",
          height: "40px",
          border: "1px solid #4D4D4D",
          background: "#151515",
          caretColor: "white",
          color: "white",
          fontFamily: "matterMedium",
          borderRadius: "3px",
          outline: "none",
        }}
      />
    </div>
  );
};

export default OtpInputComp;
