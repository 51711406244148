import React from "react";
import AppSkeleton from "..";
import Styles from "./musicSkelenton.module.scss";
const MusicSkelenton = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__top}>
        <AppSkeleton count={1} height={80} />
      </div>
      <div className={Styles.wrapper__footer}>
        <AppSkeleton count={1} height={12} />
      </div>
    </div>
  );
};

export default MusicSkelenton;
