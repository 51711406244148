import React from "react";
import SidenavModal from "../../modalViews/SidenavModal";
import UploadSongModal from "../../modalViews/UploadSongModal";
import { useAppSelector } from "../../redux/reducers/hooks";
import AuthGuard from "../../utilities/AuthGaurd";

import Styles from "./Soundbar.module.scss";
import SoundBoardRoute from "./SoundboardRoute";

const SoundBoard = () => {
  const { isShowUploadModal } = useAppSelector((state) => state?.uploadModal);
  const { isShowSidebarModal } = useAppSelector((state) => state?.sidebarModal);

  return (
    <div className={Styles.wrapper}>
      {isShowUploadModal && <UploadSongModal />}
      <div className={Styles.wrapper__sidebar}>
        {isShowSidebarModal && <SidenavModal />}
      </div>
      <AuthGuard>
        <SoundBoardRoute />
      </AuthGuard>
    </div>
  );
};

export default SoundBoard;
