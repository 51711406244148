import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import services from "../../controllers";
import { useAppSelector } from "../../redux/reducers/hooks";
import {
  setRefetchUser,
  setUserProfile,
} from "../../redux/reducers/userProfileSlice";
import { getItemFromSessionStorage } from "../helpers/appHealper";

export interface IAuthComp {
  children?: any;
}

const AuthGuard: FunctionComponent<IAuthComp> = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { refetchUser } = useAppSelector((state) => state?.userProfileDetails);

  const token = getItemFromSessionStorage("token");

  const getUser = () => {
    if (token) {
      let getUserFromServer = new services().getService("auth/getme");
      getUserFromServer
        .then((res) => {
          let data = {
            ...res?.data,
            token,
          };
          dispatch(setUserProfile(data));
        })
        .catch((error) => {
          dispatch(setUserProfile({}));
        });
    }
  };

  useEffect(() => {
    if (token || refetchUser) {
      getUser();
    }

    return () => {
      dispatch(setRefetchUser(false));
    };
  }, [token, refetchUser]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, pathname, navigate]);

  return children;
};

export default AuthGuard;
