import React from "react";
interface IAnalyticsIcon {
  color?: string;
}
const AnalyticsIcon = ({ color }: IAnalyticsIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 12.125H5V8.375H3.5V12.125H2.75V7.625H5.75V12.125ZM9.5 12.125H8.75V6.125H7.25V12.125H6.5V5.375H9.5V12.125ZM13.25 12.125H12.5V3.875H11V12.125H10.25V3.125H13.25V12.125ZM2 12.125H14V12.875H2V12.125Z"
        fill={color || "#AAAAAA"}
      />
    </svg>
  );
};

export default AnalyticsIcon;
