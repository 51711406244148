/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserProfileSlice {
  userProfile: any;
  refetchUser: boolean;
}

const initialState: IUserProfileSlice = {
  userProfile: {},
  refetchUser: false,
};

export const userProfileSlice = createSlice({
  name: "userProfileDetails",
  initialState,
  reducers: {
    setUserProfile: (state: IUserProfileSlice, action: PayloadAction<any>) => {
      const { payload } = action;
      state.userProfile = payload;
    },
    setRefetchUser: (
      state: IUserProfileSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.refetchUser = payload;
    },
  },
});

const { actions, reducer } = userProfileSlice;

export const { setUserProfile, setRefetchUser } = actions;

export default reducer;
