import React from "react";
import { useDispatch } from "react-redux";
import AppModal from "../../components/appModal";
import Sidebar from "../../components/dashboardWrapper/sideBar";
import { setIsShowSidebarModal } from "../../redux/reducers/sidebarModal";

const SidenavModal = () => {
  const dispatch = useDispatch();
  return (
    <AppModal handleClickAway={() => dispatch(setIsShowSidebarModal(false))}>
      <Sidebar />
    </AppModal>
  );
};

export default SidenavModal;
