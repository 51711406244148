import React from "react";
import ClickAwayListener from "react-click-away-listener";
import Styles from "./appCenterModal.module.scss";
import CancelIcon from "../../assets/svgs/CancelIcon";

interface IAppModal {
  children: any;
  handleClickAway: any;
  title?: string;
  icon?: any;
  subTitle?: string;
}
const AppCenterModal = ({
  children,
  handleClickAway,
  title,
  subTitle,
  icon,
}: IAppModal) => {
  return (
    <div className={Styles.wrapper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__cancelIcon}>
            <span onClick={handleClickAway}>
              <CancelIcon />
            </span>
          </div>
          <div className={Styles.wrapper__bodyContainer}>
            <div className={Styles.wrapper__icon}>{icon}</div>
            <div className={Styles.wrapper__title}>{title}</div>
            {subTitle && (
              <div className={Styles.wrapper__subtitle}>{subTitle}</div>
            )}
            {children}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default AppCenterModal;
