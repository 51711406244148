import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16732 8.33366C3.66732 8.33366 3.33398 8.00032 3.33398 7.50033C3.33398 4.25033 5.91732 1.66699 9.16732 1.66699C11.6673 1.66699 13.9173 3.33366 14.7507 5.75033C14.834 6.16699 14.6673 6.66699 14.1673 6.75033C13.7507 6.91699 13.2507 6.66699 13.084 6.25033C12.584 4.50033 11.0007 3.33366 9.16732 3.33366C6.83398 3.33366 5.00065 5.16699 5.00065 7.50033C5.00065 8.00032 4.66732 8.33366 4.16732 8.33366Z"
        fill="white"
      />
      <path
        d="M15.0006 15C14.5006 15 14.1673 14.6667 14.1673 14.1667C14.1673 13.6667 14.5006 13.3333 15.0006 13.3333C16.834 13.3333 18.334 11.8333 18.334 10C18.334 8.16667 16.834 6.66667 15.0006 6.66667C14.7506 6.66667 14.4173 6.66667 14.1673 6.75C13.7506 6.83333 13.2506 6.58333 13.1673 6.16667C13.084 5.75 13.334 5.25 13.7506 5.16667C14.1673 5.08333 14.584 5 15.0006 5C17.7506 5 20.0006 7.25 20.0006 10C20.0006 12.75 17.7506 15 15.0006 15ZM6.66732 15H4.16732C3.66732 15 3.33398 14.6667 3.33398 14.1667C3.33398 13.6667 3.66732 13.3333 4.16732 13.3333H6.66732C7.16732 13.3333 7.50065 13.6667 7.50065 14.1667C7.50065 14.6667 7.16732 15 6.66732 15Z"
        fill="white"
      />
      <path
        d="M15 15.0003H13.3333C12.8333 15.0003 12.5 14.667 12.5 14.167C12.5 13.667 12.8333 13.3337 13.3333 13.3337H15C15.5 13.3337 15.8333 13.667 15.8333 14.167C15.8333 14.667 15.5 15.0003 15 15.0003ZM4.16667 15.0003C1.83333 15.0003 0 13.167 0 10.8337C0 8.50033 1.83333 6.66699 4.16667 6.66699C4.66667 6.66699 5 7.00033 5 7.50033C5 8.00033 4.66667 8.33366 4.16667 8.33366C2.75 8.33366 1.66667 9.41699 1.66667 10.8337C1.66667 12.2503 2.75 13.3337 4.16667 13.3337C4.66667 13.3337 5 13.667 5 14.167C5 14.667 4.66667 15.0003 4.16667 15.0003ZM10 18.3337C9.5 18.3337 9.16667 18.0003 9.16667 17.5003V9.16699C9.16667 8.66699 9.5 8.33366 10 8.33366C10.5 8.33366 10.8333 8.66699 10.8333 9.16699V17.5003C10.8333 18.0003 10.5 18.3337 10 18.3337Z"
        fill="white"
      />
      <path
        d="M7.49935 12.4997C7.24935 12.4997 7.08268 12.4163 6.91602 12.2497C6.58268 11.9163 6.58268 11.4163 6.91602 11.083L9.41602 8.58301C9.74935 8.24967 10.2493 8.24967 10.5827 8.58301C10.916 8.91634 10.916 9.41634 10.5827 9.74967L8.08268 12.2497C7.91602 12.4163 7.74935 12.4997 7.49935 12.4997Z"
        fill="white"
      />
      <path
        d="M12.4993 12.4997C12.2493 12.4997 12.0827 12.4163 11.916 12.2497L9.41602 9.74967C9.08268 9.41634 9.08268 8.91634 9.41602 8.58301C9.74935 8.24967 10.2493 8.24967 10.5827 8.58301L13.0827 11.083C13.416 11.4163 13.416 11.9163 13.0827 12.2497C12.916 12.4163 12.7493 12.4997 12.4993 12.4997Z"
        fill="white"
      />
    </svg>
  );
};

export default UploadIcon;
