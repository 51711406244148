import React from "react";

const EyeClose = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99993 5.25009C6.26393 5.25009 3.93096 6.86756 2.97049 9.14569C2.95136 9.19106 2.92348 9.23222 2.88844 9.26682C2.8534 9.30142 2.81189 9.32878 2.76627 9.34733C2.72066 9.36589 2.67184 9.37528 2.6226 9.37497C2.57336 9.37466 2.52466 9.36465 2.47929 9.34552C2.43391 9.32638 2.39275 9.2985 2.35815 9.26346C2.32356 9.22842 2.2962 9.18691 2.27764 9.1413C2.25909 9.09569 2.2497 9.04687 2.25001 8.99762C2.25032 8.94838 2.26033 8.89969 2.27946 8.85431C3.35861 6.29494 5.9658 4.5 8.99993 4.5C12.0341 4.5 14.6412 6.29494 15.7204 8.85431C15.759 8.94595 15.7597 9.04918 15.7222 9.1413C15.6847 9.23342 15.6122 9.30688 15.5206 9.34552C15.4289 9.38415 15.3257 9.38481 15.2336 9.34733C15.1415 9.30986 15.068 9.23732 15.0294 9.14569C14.0689 6.86756 11.7359 5.25009 8.99993 5.25009Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99993 12.7499C6.26393 12.7499 3.93096 11.1324 2.97049 8.85429C2.95136 8.80891 2.92348 8.76775 2.88844 8.73315C2.8534 8.69856 2.81189 8.6712 2.76627 8.65264C2.72066 8.63409 2.67184 8.6247 2.6226 8.62501C2.57336 8.62532 2.52466 8.63533 2.47929 8.65446C2.43391 8.67359 2.39275 8.70147 2.35815 8.73651C2.32356 8.77155 2.2962 8.81306 2.27764 8.85867C2.25909 8.90429 2.2497 8.95311 2.25001 9.00235C2.25032 9.05159 2.26033 9.10029 2.27946 9.14566C3.35861 11.705 5.9658 13.5 8.99993 13.5C12.0341 13.5 14.6412 11.705 15.7204 9.14566C15.759 9.05403 15.7597 8.95079 15.7222 8.85867C15.6847 8.76656 15.6122 8.6931 15.5206 8.65446C15.4289 8.61582 15.3257 8.61517 15.2336 8.65264C15.1415 8.69012 15.068 8.76265 15.0294 8.85429C14.0689 11.1324 11.7359 12.7499 8.99993 12.7499Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99991 7.12509C8.75175 7.12205 8.50546 7.16831 8.27532 7.26117C8.04517 7.35403 7.83575 7.49165 7.65919 7.66606C7.48264 7.84047 7.34246 8.04819 7.24679 8.27718C7.15111 8.50617 7.10185 8.75187 7.10185 9.00005C7.10185 9.24822 7.15111 9.49392 7.24679 9.72292C7.34246 9.95191 7.48264 10.1596 7.65919 10.334C7.83575 10.5084 8.04517 10.6461 8.27532 10.7389C8.50546 10.8318 8.75175 10.878 8.99991 10.875C9.4932 10.869 9.96424 10.6688 10.3109 10.3178C10.6576 9.96683 10.852 9.49338 10.852 9.00005C10.852 8.50672 10.6576 8.03326 10.3109 7.6823C9.96424 7.33133 9.4932 7.13114 8.99991 7.12509ZM6.375 8.99991C6.375 8.6552 6.4429 8.31387 6.57481 7.9954C6.70672 7.67693 6.90007 7.38756 7.14382 7.14382C7.38756 6.90007 7.67693 6.70672 7.9954 6.57481C8.31387 6.4429 8.6552 6.375 8.99991 6.375C9.34461 6.375 9.68595 6.4429 10.0044 6.57481C10.3229 6.70672 10.6122 6.90007 10.856 7.14382C11.0997 7.38756 11.2931 7.67693 11.425 7.9954C11.5569 8.31387 11.6248 8.6552 11.6248 8.99991C11.6248 9.69607 11.3483 10.3637 10.856 10.856C10.3637 11.3483 9.69607 11.6248 8.99991 11.6248C8.30374 11.6248 7.63608 11.3483 7.14382 10.856C6.65155 10.3637 6.375 9.69607 6.375 8.99991Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6405 2.3599C15.7868 2.50643 15.7868 2.7438 15.6405 2.89034L2.89005 15.6405C2.85523 15.6753 2.81388 15.7029 2.76838 15.7218C2.72288 15.7406 2.67412 15.7503 2.62488 15.7503C2.57563 15.7503 2.52688 15.7406 2.48139 15.7217C2.4359 15.7028 2.39457 15.6752 2.35976 15.6404C2.32495 15.6056 2.29734 15.5642 2.27851 15.5187C2.25967 15.4732 2.24999 15.4244 2.25 15.3752C2.25001 15.326 2.25973 15.2772 2.27858 15.2317C2.29744 15.1862 2.32507 15.1449 2.3599 15.1101L15.1101 2.3599C15.2563 2.21337 15.494 2.21337 15.6405 2.3599Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default EyeClose;
