import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/appButton";
import AuthWrapper from "../../../components/authWrapper";
import OtpInputComp from "../../../components/otpInput";
import services from "../../../controllers";
import { setItemToLocalStorage } from "../../../utilities/helpers/appHealper";
import useToast from "../../../utilities/hooks/useToast";
import Styles from "./ForgotPasswordCode.module.scss";
const ForgotPasswordCode = () => {
  const toast = useToast();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { email } = useParams();
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!email) {
      return navigate("/forgot-password/email");
    }
  }, [email]);

  const handleSubmit = () => {
    if (otp.length !== 4) {
      return toast.error("Your otp code must be atleast 4 digit number.");
    }

    setItemToLocalStorage("otp", otp);

    navigate("/forgot-password/create-new-password");
  };

  const handleResendCode = () => {
    setLoader(true);
    let data = {
      email: email,
    };

    const resendCode = new services().postService("auth/forgotPassword", data);
    resendCode
      .then((res) => {
        setLoader(false);
        if (res?.status === "success") {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("an error occurred, try again later.");
      });
  };

  return (
    <div className={Styles.wrapper}>
      <AuthWrapper>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__title}>Forgot Password</div>
          <div className={Styles.wrapper__subtitle}>
            We sent a 4 digit code to <span>{email}.</span> Please input the
            code in the space provided below.
          </div>
          <div className={Styles.wrapper__otpContainer}>
            <OtpInputComp updateOtp={(data: any) => setOtp(data)} />
          </div>
          <div className={Styles.wrapper__resendCode}>
            Didn’t get the code?{" "}
            {!loader && (
              <span
                className={`linear-primary-color`}
                onClick={handleResendCode}
              >
                Resend Code
              </span>
            )}
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Reset Password"
              onClick={handleSubmit}
              loading={loader}
            />
          </div>
          <div className={Styles.wrapper__changeEmail}>
            Wrong Email?{" "}
            <Link to="/forgot-password/email" className="linear-primary-color">
              Change Email Address
            </Link>
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default ForgotPasswordCode;
