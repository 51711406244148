import React from "react";
import ClickAwayListener from "react-click-away-listener";
import CancelIcon from "../../assets/svgs/CancelIcon";
import Styles from "./AppModal.module.scss";
interface IAppModal {
  children: any;
  handleClickAway: any;
  title?: string;
}
const AppModal = ({ children, handleClickAway, title }: IAppModal) => {
  return (
    <div className={Styles.wrapper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__header}>
            <div className={Styles.wrapper__title}>{title}</div>
            <div>
              <CancelIcon onClick={handleClickAway} />
            </div>
          </div>
          <div className={Styles.wrapper__bodyContainer}>{children}</div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default AppModal;
