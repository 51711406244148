import React from "react";
import AppAlternativeSearchInput from "../appSearchInput/AppAlternativeSearch";
import AppTab from "../appTab";
import Styles from "./AppSearchAndTab.module.scss";
const AppSearchAndTabs = () => {
  const details = [
    {
      id: 1,
      name: "All Audojis",
      link: "/dashboard",
      activeLink: ["/dashboard"],
    },
    {
      id: 2,
      name: "My Soundboard",
      link: "/dashboard/soundboard?page=1",
      activeLink: ["/dashboard/soundboard"],
    },
  ];

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__tab}>
        <AppTab data={details} />
      </div>
      <div className={Styles.wrapper__search}>
        <AppAlternativeSearchInput
          placeholder="Search for Audojis"
          type="audoji"
        />
      </div>
    </div>
  );
};

export default AppSearchAndTabs;
