import React from "react";
interface IEditIcon {
  width?: string;
  height?: string;
  color?: string;
}
const EditIcon = ({ width, height, color }: IEditIcon) => {
  return (
    <svg
      width={width || "9"}
      height={height || "11"}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M1.5002 8.90005C1.5502 8.90005 1.5502 8.90005 1.6002 8.90005L2.7502 8.70005C3.2502 8.60005 3.7002 8.35005 4.0502 8.00005L8.0502 4.05005L8.5502 3.50005C8.8502 3.25005 9.0002 2.85005 9.0002 2.45005C9.0002 2.05005 8.8502 1.70005 8.5502 1.40005C8.0002 0.850049 7.0502 0.850049 6.4502 1.40005L5.9502 1.90005L1.9502 5.85005C1.6002 6.20005 1.3502 6.65005 1.2502 7.15005L1.0002 8.30005C0.950195 8.45005 1.0002 8.65005 1.1502 8.75005C1.2502 8.85005 1.3502 8.90005 1.5002 8.90005ZM7.1502 2.15005C7.35019 1.95005 7.6502 1.95005 7.8502 2.15005C8.0002 2.25005 8.0002 2.40005 8.0002 2.45005C8.0002 2.60005 7.9502 2.70005 7.8502 2.80005L7.7002 3.00005L7.0002 2.30005L7.1502 2.15005ZM8.5002 9.50005H1.5002C1.2002 9.50005 1.0002 9.70005 1.0002 10C1.0002 10.3 1.2002 10.5 1.5002 10.5H8.5002C8.8002 10.5 9.0002 10.3 9.0002 10C9.0002 9.70005 8.8002 9.50005 8.5002 9.50005Z"
        fill="url(#paint0_linear_230_915)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_230_915"
          x1="0.981445"
          y1="0.987549"
          x2="9.0002"
          y2="0.987549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || "#EF4136"} />
          <stop offset="1" stopColor={color || "#FBB040"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EditIcon;
