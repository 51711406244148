import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_786_1535)">
        <path
          d="M4.90362 8.64806C6.97216 8.64806 8.64904 6.97118 8.64904 4.90264C8.64904 2.83411 6.97216 1.15723 4.90362 1.15723C2.83508 1.15723 1.1582 2.83411 1.1582 4.90264C1.1582 6.97118 2.83508 8.64806 4.90362 8.64806Z"
          stroke="#AAAAAA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.50781 7.70215L8.97615 9.16673"
          stroke="#AAAAAA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_786_1535">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
