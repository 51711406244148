import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/svgs/SearchIcon";
import {
  setAudojiSearch,
  setMusicSearch,
} from "../../redux/reducers/appSearch";
import { useAppSelector } from "../../redux/reducers/hooks";
import Styles from "./appSearchInput.module.scss";
interface ISearchInput {
  placeholder: string;
  type: string;
}
const SearchInput = ({ placeholder, type }: ISearchInput) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { musicsSearch } = useAppSelector((state) => state?.appSearchs);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMusicSearch(""));
    dispatch(setAudojiSearch(""));
    setSearchTerm("");
  }, [pathname]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(debounceId);
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(setAudojiSearch(""));

    if (type === "musics" && debouncedSearchTerm) {
      dispatch(setMusicSearch(debouncedSearchTerm));

      return;
    }

    dispatch(setMusicSearch(""));
  }, [debouncedSearchTerm, type, navigate, musicsSearch, pathname]);

  useEffect(() => {
    if (!musicsSearch) {
      setSearchTerm("");
    }
  }, [musicsSearch]);

  return (
    <div className={Styles.wrapper}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={placeholder || "Search for"}
      />
      <div className={Styles.wrapper__searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
