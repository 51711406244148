import { getItemFromSessionStorage } from "../utilities/helpers/appHealper";

export default class services {
  userToken = getItemFromSessionStorage("token") || "";

  async postService(pathName: string, data: any, isAiBaseUrl?: string) {
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: this.userToken,
          },
          body: JSON.stringify({ ...data }),
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async postWithBaseUrlService(pathName: string, data: any) {
    try {
      let response = await fetch(`${pathName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: this.userToken,
        },
        body: JSON.stringify({ ...data }),
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async postWithFormDataService(
    pathName: string,
    data: any,
    isAiBaseUrl?: string,
  ) {
    let fordata = new FormData();
    fordata.append("", data);
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            // Accept: "*/*",
            // Authorization: this.userToken,
            multipart: "form-data",
          },
          body: fordata,
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async patchService(pathName: string, data: any, isAiBaseUrl?: string) {
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: this.userToken,
          },
          body: JSON.stringify({ ...data }),
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async getService(pathName?: string, isAiBaseUrl?: string) {
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: this.userToken,
          },
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async putService(pathName?: string, data?: any, isAiBaseUrl?: string) {
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: this.userToken,
          },
          body: JSON.stringify({ ...data }),
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async deleteService(pathName?: string, data?: any, isAiBaseUrl?: string) {
    try {
      let response = await fetch(
        `${isAiBaseUrl ? process.env.REACT_APP_AIBASEURL : process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: this.userToken,
          },
          body: JSON.stringify({ ...data }),
        },
      );

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
  async getWithoutBaseUrlService(pathName: string) {
    try {
      let response = await fetch(`${pathName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async picUpdateService(pathName: string, fieldName: any, fileType: any) {
    try {
      const data = new FormData();
      data.append(fieldName, fileType);
      let response = await fetch(
        `${process.env.REACT_APP_BASEURL}${pathName}`,
        {
          method: "POST",
          headers: {
            Authorization: this.userToken,
          },
          body: data,
        },
      );
      return await response.json();
    } catch (err) {
      throw err;
    }
  }
}
