import React from "react";
import Logo from "../../assets/svgs/Logo";

import Styles from "./appLogo.module.scss";
interface IAppLogo {
  logoWidth?: string;
  titleFontSize?: string;
  subTitleFontSize?: string;
}
const AppLogo = ({ logoWidth, titleFontSize, subTitleFontSize }: IAppLogo) => {
  return (
    <div className={Styles.wrapper}>
      <div
        className={Styles.wrapper__logoContainer}
        style={{ width: logoWidth, height: logoWidth }}
      >
        <div
          className={Styles.wrapper__logoImage}
          style={{ width: logoWidth, height: logoWidth }}
        >
          <Logo width={logoWidth || "48px"} height={logoWidth || "48px"} />
        </div>
      </div>
      <div className={Styles.wrapper__contentContainer}>
        <div
          className={Styles.wrapper__title}
          style={{ fontSize: titleFontSize }}
        >
          AUDOJI
        </div>
        <div
          className={Styles.wrapper__subtitle}
          style={{ fontSize: subTitleFontSize }}
        >
          SAY IT WITH SONG
        </div>
      </div>
    </div>
  );
};

export default AppLogo;
