import React, { useEffect, useMemo, useState } from "react";

import SoundBoardCard from "../../../../components/soundBoardCard";
import UploadCard from "../../../../components/UploadCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/reducers/hooks";
import {
  setIsRefetchMusic,
  setIsShowUploadModal,
} from "../../../../redux/reducers/uploadModalSlice";

import Styles from "./MyTopSound.module.scss";
import services from "../../../../controllers";
import MusicSkelenton from "../../../../components/appSkeleton/musicSkelenton";
import UseCustomResizer from "../../../../utilities/hooks/CustomResizer";
import AppEmptyState from "../../../../components/appEmptyState";
import PrevIcon from "../../../../assets/svgs/PrevIcon";
import NextIcon from "../../../../assets/svgs/NextIcon";
import {
  setArtistName,
  setMusicId,
  setMusicImage,
  setMusicTitle,
  setSpotifyLink,
} from "../../../../redux/reducers/songTitle";
import SearchInput from "../../../../components/appSearchInput";
import { setAudojiSearch } from "../../../../redux/reducers/appSearch";
import { useLocation } from "react-router-dom";
import { setMusicEditDetails } from "../../../../redux/reducers/editSongSlice";
import DeleteSongModal from "../../../../modalViews/DeleteSongModal";

interface ITopSounds {
  onClickSong: any;
}
const TopSounds = ({ onClickSong }: ITopSounds) => {
  const dispatch = useAppDispatch();
  const { isMobile } = UseCustomResizer({ width: 651 });
  const { pathname } = useLocation();
  const { userProfile } = useAppSelector((state) => state?.userProfileDetails);
  const { isRefetchMusic } = useAppSelector((state) => state?.uploadModal);
  const { musicTitle } = useAppSelector((state) => state?.songDetails);
  const { musicsSearch } = useAppSelector((state) => state?.appSearchs);
  const [loader, setLoader] = useState(true);
  const [songdetails, setSongdetails]: any = useState([]);
  const numberOfArrays = isMobile ? [0, 1, 2] : [0, 1, 2, 3, 4, 5];
  const [pageToshow, setPageToShow] = useState(numberOfArrays);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getSongs = (musicTitleSearch?: string) => {
    setLoader(true);
    let getData = new services().getService(
      `audiofiles?user_id=${userProfile?._id}&title=${musicTitleSearch || ""}`,
      "isAiBaseUrl",
    );
    getData
      .then((res) => {
        setLoader(false);
        setSongdetails(res);
        dispatch(setMusicTitle(res[0]?.title || ""));
        dispatch(setMusicId(res[0]?.id || ""));
        dispatch(setMusicImage(res[0]?.cover_image || ""));
        dispatch(setArtistName(res[0]?.artiste || ""));
        dispatch(setSpotifyLink(res[0]?.spotify_link || ""));
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useMemo(() => {
    if (userProfile?._id) {
      getSongs(musicsSearch);
    }
  }, [userProfile, musicsSearch, pathname, setSongdetails]);

  useEffect(() => {
    if (isRefetchMusic && userProfile?._id) {
      getSongs();
    }
    return () => {
      dispatch(setIsRefetchMusic(false));
    };
  }, [userProfile?._id, isRefetchMusic]);

  const handleNext = () => {
    if (pageToshow[numberOfArrays?.length - 1] < songdetails?.length - 1) {
      setPageToShow((prev) => prev.map((list) => list + 1));
    } else {
      setPageToShow(numberOfArrays);
    }
  };

  const handlePrev = () => {
    if (pageToshow[0] !== 0) {
      setPageToShow((prev) => prev.map((list) => list - 1));
    } else {
      setPageToShow(numberOfArrays);
    }
  };

  useEffect(() => {
    setPageToShow(numberOfArrays);
  }, [isMobile]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__titleContainer}>
        <div className={Styles.wrapper__title}>My Music</div>

        <div className={`${Styles.wrapper__searchInput}`}>
          <div className={Styles.wrapper__searchInnerInput}>
            <SearchInput placeholder="Search for songs" type="musics" />
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div
          className={`${Styles.wrapper__cardsContainer} ${songdetails?.length || loader ? "col-xl-9 col-lg-12" : "col-12"}`}
        >
          <div className={Styles.wrapper__cards}>
            {songdetails?.length || loader ? (
              <div>
                {isMobile ? (
                  <div className={Styles.wrapper__soundContainerRowMobile}>
                    {songdetails?.map((list: any, index: number) => {
                      return (
                        <div className={Styles.wrapper__soundContainerMobile}>
                          <div className={Styles.wrapper__soundCardMobile}>
                            <SoundBoardCard
                              handleSelect={(title: string) => {
                                dispatch(setMusicId(list?.id));
                                dispatch(setMusicTitle(title));
                                dispatch(
                                  setMusicImage(list?.cover_image || ""),
                                );
                                dispatch(setArtistName(list?.artiste || ""));
                                dispatch(
                                  setSpotifyLink(list?.spotify_link || ""),
                                );
                                dispatch(setAudojiSearch(""));
                                onClickSong();
                              }}
                              title={list?.title}
                              image={list?.cover_image}
                              key={list?.owner}
                              divIndex={index}
                              selectedSong={musicTitle}
                              handleDelete={() => {
                                dispatch(
                                  setMusicEditDetails({
                                    coverImage: list?.cover_image,
                                    title: list?.title,
                                    spotifyLink: list?.spotify_link,
                                    artiste: list?.artiste,
                                    id: list?.id,
                                  }),
                                );
                                setShowDeleteModal(true);
                              }}
                              handleEdit={() => {
                                dispatch(setIsShowUploadModal(true));
                                dispatch(
                                  setMusicEditDetails({
                                    coverImage: list?.cover_image,
                                    title: list?.title,
                                    spotifyLink: list?.spotify_link,
                                    artiste: list?.artiste,
                                    id: list?.id,
                                  }),
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={Styles.wrapper__soundBoardWrapper}>
                    {!loader && songdetails?.length > 6 && (
                      <div>
                        <div
                          className={Styles.wrapper__prevCircle}
                          onClick={handlePrev}
                        >
                          <PrevIcon />
                        </div>
                      </div>
                    )}

                    <div className={Styles.wrapper__soundBoardRowContainer}>
                      <div>
                        <div className="row">
                          {pageToshow?.map((list: any, index: number) =>
                            loader ? (
                              <div
                                className={`${Styles.wrapper__soundCards} col-xl-2 col-md-2 col-sm-2 col-xs-4 col-4`}
                                key={index}
                              >
                                <MusicSkelenton />
                              </div>
                            ) : !songdetails[list]?.title ? (
                              ""
                            ) : (
                              <div
                                className={`${Styles.wrapper__soundCards} col-xl-2 col-lg-2 col-sm-2 col-xs-4 col-4`}
                                key={index}
                              >
                                <SoundBoardCard
                                  handleSelect={(title: string) => {
                                    dispatch(setMusicId(songdetails[list]?.id));
                                    dispatch(setMusicTitle(title));
                                    dispatch(
                                      setMusicImage(
                                        songdetails[list]?.cover_image || "",
                                      ),
                                    );
                                    dispatch(
                                      setArtistName(
                                        songdetails[list]?.artiste || "",
                                      ),
                                    );
                                    dispatch(
                                      setSpotifyLink(
                                        songdetails[list]?.spotify_link || "",
                                      ),
                                    );
                                    onClickSong();
                                    dispatch(setAudojiSearch(""));
                                  }}
                                  title={songdetails[list]?.title}
                                  image={songdetails[list]?.cover_image}
                                  key={songdetails[list]?.owner}
                                  divIndex={index}
                                  dataLength={pageToshow?.length}
                                  selectedSong={musicTitle}
                                  handleDelete={() => {
                                    dispatch(
                                      setMusicEditDetails({
                                        coverImage:
                                          songdetails[list]?.cover_image,
                                        title: songdetails[list]?.title,
                                        spotifyLink:
                                          songdetails[list]?.spotify_link,
                                        artiste: songdetails[list]?.artiste,
                                        id: songdetails[list]?.id,
                                      }),
                                    );
                                    setShowDeleteModal(true);
                                  }}
                                  handleEdit={() => {
                                    dispatch(setIsShowUploadModal(true));
                                    dispatch(
                                      setMusicEditDetails({
                                        coverImage:
                                          songdetails[list]?.cover_image,
                                        title: songdetails[list]?.title,
                                        spotifyLink:
                                          songdetails[list]?.spotify_link,
                                        artiste: songdetails[list]?.artiste,
                                        id: songdetails[list]?.id,
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                    {!loader && songdetails?.length > 6 && (
                      <div>
                        <div
                          className={Styles.wrapper__nextCircle}
                          onClick={handleNext}
                        >
                          <NextIcon />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <AppEmptyState
                // title="My Music"
                titleMarginBottom="92px"
                onClick={() => dispatch(setIsShowUploadModal(true))}
                btnTitle={!musicsSearch ? "Upload a Song" : ""}
                content={
                  !musicsSearch
                    ? "Welcome to the Soundstage"
                    : "No record fouund."
                }
                subContent={
                  !musicsSearch
                    ? "Welcome to your personal music space! Since you haven't uploaded any songs yet, no audojis have been created. Go ahead and upload your first song and help your fans express their emotions better! "
                    : ""
                }
                height={!musicsSearch ? "" : "60vh"}
              />
            )}
          </div>
        </div>
        {(songdetails?.length || loader) && (
          <div className={`${Styles.wrapper__card} col-xl-3 col-lg-5 col-sm-6`}>
            <UploadCard
              title="Upload song to create audoji"
              // subTitle="This is a short text telling the creator why they should upload a song"
              onClick={() => dispatch(setIsShowUploadModal(true))}
            />
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteSongModal
          onClose={() => {
            setShowDeleteModal(false);
            dispatch(setMusicEditDetails(null));
          }}
        />
      )}
    </div>
  );
};

export default TopSounds;
