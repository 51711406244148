import React from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./AppTab.module.scss";
interface IAppTab {
  data: any;
}
const AppTab = ({ data }: IAppTab) => {
  const { pathname } = useLocation();

  return (
    <div className={Styles.wrapper}>
      {data?.map((list: any, index: number) => (
        <Link to={list?.link} key={index}>
          <div
            className={
              list?.activeLink?.includes(pathname)
                ? Styles.wrapper__activeTab
                : Styles.wrapper__tab
            }
          >
            {list?.name}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default AppTab;
