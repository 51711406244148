import React from "react";
import { Route, Routes } from "react-router-dom";
import AppLoader from "../../../components/appLoader";
import DashboardWrapper from "../../../components/dashboardWrapper";
import Loading from "../../../components/Loading";
import { useAppSelector } from "../../../redux/reducers/hooks";
import Home from "../Home";
import Settings from "../Settings";
const Analytics = React.lazy(() => import("../Analytics"));
// const FeaturedSong = React.lazy(() => import("../FeaturedSong"));
const NotFound = React.lazy(() => import("../../../components/NotFound"));

const SoundBoardRoute = () => {
  const { isShowBackgroundLoader } = useAppSelector(
    (state) => state?.appLoaderBackground,
  );
  return (
    <DashboardWrapper>
      {isShowBackgroundLoader && <AppLoader />}

      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route index path="/*" element={<Home />} />
          <Route path="/analytics" element={<Analytics />} />
          {/* <Route path="/featured-songs" element={<FeaturedSong />} /> */}
          <Route path="/settings/*" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </DashboardWrapper>
  );
};

export default SoundBoardRoute;
