/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAppLoaderSlice {
  isShowBackgroundLoader: boolean;
}

const initialState: IAppLoaderSlice = {
  isShowBackgroundLoader: false,
};

export const appLoaderSlice = createSlice({
  name: "appLoaderBackground",
  initialState,
  reducers: {
    setIsShowBackgroundLoader: (
      state: IAppLoaderSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.isShowBackgroundLoader = payload;
    },
  },
});

const { actions, reducer } = appLoaderSlice;

export const { setIsShowBackgroundLoader } = actions;

export default reducer;
