import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../../components/appButton";
import AuthWrapper from "../../../components/authWrapper";
import TextInput from "../../../components/TextInput";
import services from "../../../controllers";
import useToast from "../../../utilities/hooks/useToast";

import Styles from "./ForgotPasswordVerificationEmail.module.scss";
const ForgotPasswordVerificationEmail = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const forgotPasswordValidationSchema = Yup?.object()?.shape({
    email: Yup.string().required("Required"),
  });

  const forgotPasswordFormik = useFormik({
    validationSchema: forgotPasswordValidationSchema,
    initialValues: {
      email: "",
    },
    onSubmit: async (values: any) => {
      setLoader(true);
      let data = {
        email: values?.email,
      };

      const createAccount = new services().postService(
        "auth/forgotPassword",
        data,
      );
      createAccount
        .then((res) => {
          setLoader(false);
          if (res?.status === "success") {
            toast.success(res?.message);
            forgotPasswordFormik.resetForm();
            navigate(`/forgot-password/email/${values?.email}`);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("an error occurred, try again later.");
        });
    },
  });

  return (
    <div className={Styles.wrapper}>
      <AuthWrapper>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__title}>Forgot Password</div>
          <div className={Styles.wrapper__subtitle}>
            Kindly enter your email address to reset your password
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={forgotPasswordFormik?.values?.email}
              name={"email"}
              onChange={forgotPasswordFormik.handleChange}
              placeholder="Enter your Email Address"
              error={
                forgotPasswordFormik.submitCount > 0 &&
                forgotPasswordFormik.errors.email
              }
            />
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Send reset code"
              onClick={forgotPasswordFormik?.handleSubmit}
              loading={loader}
            />
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default ForgotPasswordVerificationEmail;
