import React from "react";

const NextIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2999 12.9999L10.2166 8.55392C10.049 8.38475 9.95508 8.15629 9.95508 7.91822C9.95508 7.68015 10.049 7.4517 10.2166 7.28252C10.2988 7.199 10.3969 7.13267 10.505 7.08739C10.6131 7.04211 10.7292 7.0188 10.8464 7.0188C10.9636 7.0188 11.0797 7.04211 11.1878 7.08739C11.2959 7.13267 11.394 7.199 11.4763 7.28252L16.4553 12.3629C16.6225 12.5325 16.7163 12.7611 16.7163 12.9993C16.7163 13.2375 16.6225 13.4661 16.4553 13.6356L11.4763 18.716C11.394 18.7995 11.2959 18.8659 11.1878 18.9111C11.0797 18.9564 10.9636 18.9797 10.8464 18.9797C10.7292 18.9797 10.6131 18.9564 10.505 18.9111C10.3969 18.8659 10.2988 18.7995 10.2166 18.716C10.049 18.5468 9.95508 18.3184 9.95508 18.0803C9.95508 17.8422 10.049 17.6138 10.2166 17.4446L14.2999 12.9999Z"
        fill="#252525"
      />
    </svg>
  );
};

export default NextIcon;
