import React from "react";
import { Route, Routes } from "react-router-dom";
import AppStyles from "./App.module.scss";
import Loading from "./components/Loading";
import NotFound from "./components/NotFound";
import CreateAccount from "./screens/CreateAccount";
import ForgotPassword from "./screens/ForgotPassword";
import Login from "./screens/Login";
import SoundBoard from "./screens/SoundBoard";

function App() {
  return (
    <div className={AppStyles.wrapper}>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/forgot-password/*" element={<ForgotPassword />} />
          <Route path="/dashboard/*" element={<SoundBoard />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
