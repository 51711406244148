import React from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import CreateNewPassword from "./CreateNewPassword";
import ForgotPasswordCode from "./ForgotPasswordCode";
import ForgotPasswordVerificationEmail from "./ForgotPasswordVerification";

const ForgotPassword = () => {
  return (
    <div>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/email" element={<ForgotPasswordVerificationEmail />} />
          <Route path="/email/:email" element={<ForgotPasswordCode />} />
          <Route path="/create-new-password" element={<CreateNewPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default ForgotPassword;
