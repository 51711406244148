import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/svgs/SearchIcon";
import {
  setAudojiSearch,
  setMusicSearch,
} from "../../redux/reducers/appSearch";
import { useAppSelector } from "../../redux/reducers/hooks";
import Styles from "./appSearchInput.module.scss";
interface ISearchInput {
  placeholder: string;
  type: string;
}
const AppAlternativeSearchInput = ({ placeholder, type }: ISearchInput) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const { musicsSearch, audojiSearch } = useAppSelector(
    (state) => state?.appSearchs,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchTerm("");
    dispatch(setMusicSearch(""));
    dispatch(setAudojiSearch(""));
  }, [pathname]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(debounceId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (type === "audoji" && musicsSearch) {
      dispatch(setMusicSearch(musicsSearch));
    }
    if (type === "audoji" && debouncedSearchTerm) {
      dispatch(setAudojiSearch(debouncedSearchTerm));

      return;
    }

    dispatch(setAudojiSearch(""));
  }, [
    debouncedSearchTerm,
    type,
    navigate,
    audojiSearch,
    musicsSearch,
    pathname,
  ]);

  useEffect(() => {
    if (!audojiSearch) {
      setSearchTerm("");
      return;
    }
  }, [musicsSearch, audojiSearch, pathname]);

  return (
    <div className={Styles.wrapper}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={placeholder || "Search for"}
      />
      <div className={Styles.wrapper__searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default AppAlternativeSearchInput;
