import React from "react";
interface IDeleteIcon {
  width?: string;
  height?: string;
}
const DeleteIcon = ({ width, height }: IDeleteIcon) => {
  return (
    <svg
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M7.5 1.5C7.63261 1.5 7.75979 1.55268 7.85355 1.64645C7.94732 1.74021 8 1.86739 8 2H9C9.13261 2 9.25979 2.05268 9.35355 2.14645C9.44732 2.24021 9.5 2.36739 9.5 2.5C9.5 2.63261 9.44732 2.75979 9.35355 2.85355C9.25979 2.94732 9.13261 3 9 3H3C2.86739 3 2.74021 2.94732 2.64645 2.85355C2.55268 2.75979 2.5 2.63261 2.5 2.5C2.5 2.36739 2.55268 2.24021 2.64645 2.14645C2.74021 2.05268 2.86739 2 3 2H4C4 1.86739 4.05268 1.74021 4.14645 1.64645C4.24021 1.55268 4.36739 1.5 4.5 1.5H7.5Z"
        fill="#909090"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.5H9V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H4C3.73478 10.5 3.48043 10.3946 3.29289 10.2071C3.10536 10.0196 3 9.76522 3 9.5V3.5ZM4.75 4.5C4.6837 4.5 4.62011 4.52634 4.57322 4.57322C4.52634 4.62011 4.5 4.6837 4.5 4.75V9.25C4.5 9.3163 4.52634 9.37989 4.57322 9.42678C4.62011 9.47366 4.6837 9.5 4.75 9.5C4.8163 9.5 4.87989 9.47366 4.92678 9.42678C4.97366 9.37989 5 9.3163 5 9.25V4.75C5 4.6837 4.97366 4.62011 4.92678 4.57322C4.87989 4.52634 4.8163 4.5 4.75 4.5ZM7.25 4.5C7.1837 4.5 7.12011 4.52634 7.07322 4.57322C7.02634 4.62011 7 4.6837 7 4.75V9.25C7 9.3163 7.02634 9.37989 7.07322 9.42678C7.12011 9.47366 7.1837 9.5 7.25 9.5C7.3163 9.5 7.37989 9.47366 7.42678 9.42678C7.47366 9.37989 7.5 9.3163 7.5 9.25V4.75C7.5 4.6837 7.47366 4.62011 7.42678 4.57322C7.37989 4.52634 7.3163 4.5 7.25 4.5Z"
        fill="#909090"
      />
    </svg>
  );
};

export default DeleteIcon;
