import React from "react";
import "./checkInput.scss";
interface ICheckInput {
  label: any;
  isChecked: boolean;
  onChange?: any;
}
const CheckInput = ({ isChecked, label, onChange }: ICheckInput) => {
  return (
    <label className="container">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className="checkmark"></span>
      {label}
    </label>
  );
};

export default CheckInput;
