import React from "react";
interface ISoundBoardIcon {
  color?: string;
  width?: string;
  height?: string;
}
const SoundBoardIcon = ({ color, width, height }: ISoundBoardIcon) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.333 1.82651C13.243 1.76571 13.1394 1.72784 13.0313 1.71622C12.9233 1.7046 12.814 1.71958 12.713 1.75985L6.04638 4.42651C5.92339 4.47549 5.81776 4.55998 5.74296 4.66921C5.66816 4.77844 5.62758 4.90747 5.62638 5.03985V10.6665C5.3174 10.5619 4.99249 10.5123 4.66638 10.5198C3.37971 10.5198 2.37305 11.3465 2.37305 12.4065C2.37305 13.4665 3.37971 14.2932 4.66638 14.2932C5.04946 14.3092 5.43082 14.2335 5.77871 14.0723C6.12659 13.9111 6.43093 13.6691 6.66638 13.3665C6.7618 13.3085 6.84111 13.2274 6.89702 13.1307C6.95293 13.034 6.98366 12.9248 6.98638 12.8132V8.05318L12.3197 6.00652V9.33985C12.0027 9.22989 11.6685 9.17795 11.333 9.18651C10.0464 9.18651 9.03971 10.0132 9.03971 11.0732C9.03971 12.1332 10.0464 12.9598 11.333 12.9598C11.7161 12.9759 12.0975 12.9001 12.4454 12.7389C12.7933 12.5778 13.0976 12.3358 13.333 12.0332C13.4285 11.9751 13.5078 11.8941 13.5637 11.7974C13.6196 11.7007 13.6503 11.5915 13.653 11.4798V2.37318C13.6492 2.26267 13.618 2.15484 13.5621 2.05941C13.5063 1.96398 13.4275 1.88395 13.333 1.82651Z"
        fill={color || "white"}
      />
    </svg>
  );
};

export default SoundBoardIcon;
