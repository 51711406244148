import React from "react";
import SoundBoardIcon from "../../assets/svgs/SoundBoardIcon";
import Button from "../appButton";
import Styles from "./AppEmptyState.module.scss";
interface IAppEmptyState {
  title?: string;
  icon?: any;
  content?: string;
  subContent?: string;
  height?: string;
  btnTitle?: string;
  onClick?: any;
  titleMarginBottom?: string;
}
const AppEmptyState = ({
  title,
  icon,
  content,
  subContent,
  height,
  btnTitle,
  onClick,
  titleMarginBottom,
}: IAppEmptyState) => {
  return (
    <div className={Styles.wrapper} style={{ height }}>
      <div
        className={Styles.wrapper__title}
        style={{ marginBottom: titleMarginBottom }}
      >
        {title}
      </div>

      <div className={Styles.wrapper__body}>
        <div className={Styles.wrapper__details}>
          <div className={Styles.wrapper__detailsIcon}>
            {icon || (
              <div className={Styles.wrapper__detailsIconContainer}>
                <SoundBoardIcon color="#E05F3F" height="56px" width="56px" />
              </div>
            )}
          </div>
          <div className={Styles.wrapper__detailsContent}>
            {content || "Welcome to the Soundstage"}
          </div>
          {subContent && (
            <div className={Styles.wrapper__detailsSubContent}>
              <div className={Styles.wrapper__detailsSubInnerContent}>
                {subContent}
              </div>
            </div>
          )}

          {btnTitle && (
            <div className={Styles.wrapper__btnContent}>
              <Button title={btnTitle} onClick={onClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppEmptyState;
