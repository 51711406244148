import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appLoaderReducer from "../appLoader";
import appSearchReducer from "../appSearch";
import editMusicDetailsReducer from "../editSongSlice";
import sidebarModalReducer from "../sidebarModal";
import songDetailsReducer from "../songTitle";
import uploadModalReducer from "../uploadModalSlice";
import userProfileReducer from "../userProfileSlice";

export const store = configureStore({
  reducer: {
    uploadModal: uploadModalReducer,
    sidebarModal: sidebarModalReducer,
    userProfileDetails: userProfileReducer,
    appLoaderBackground: appLoaderReducer,
    songDetails: songDetailsReducer,
    appSearchs: appSearchReducer,
    editMusicDetails: editMusicDetailsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["bills/setBillSuccessData"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload.subtitle.$$typeof"],
        // Ignore these paths in the state
        ignoredPaths: ["bills.billSuccessData.subtitle"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
