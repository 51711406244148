import React, { useState } from "react";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import Button from "../../components/appButton";
import AppCenterModal from "../../components/appCenterModal";
import services from "../../controllers";
import { setMusicEditDetails } from "../../redux/reducers/editSongSlice";
import { useAppDispatch, useAppSelector } from "../../redux/reducers/hooks";
import { setIsRefetchMusic } from "../../redux/reducers/uploadModalSlice";
import useToast from "../../utilities/hooks/useToast";
import Styles from "./DeleteSongModal.module.scss";
interface IDeleteSongModal {
  onClose: () => void;
}
const DeleteSongModal = ({ onClose }: IDeleteSongModal) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [loader, setLoader] = useState(false);
  const { musicEditDetails } = useAppSelector(
    (state) => state?.editMusicDetails,
  );
  const handleSubmit = () => {
    setLoader(true);
    let editLyrics = new services().deleteService(
      `audiofiles/${musicEditDetails?.id}`,
      "",
      "isAiBaseUrl",
    );
    editLyrics
      .then((res) => {
        setLoader(false);
        onClose();
        dispatch(setIsRefetchMusic(true));
        dispatch(setMusicEditDetails(null));
        return toast.success("Operation successful.");
      })
      .catch((error) => {
        setLoader(false);

        toast.error(
          error?.response?.data?.detail ||
            "an error occurred, try again later.",
        );
      });
  };

  return (
    <div className={Styles.wrapper}>
      <AppCenterModal
        handleClickAway={onClose}
        title="Delete Song?"
        icon={<DeleteIcon width="40px" height="40px" />}
        subTitle={`Heads up!  Deleting "${musicEditDetails?.title}" will also permanently remove any Audojis generated for this song. This action can't be undone. Are you sure you want to proceed?`}
      >
        <div className={Styles.wrapper__buttonContainer}>
          <div className={Styles.wrapper__cancelBtn}>
            <Button
              title="No, Cancel."
              fontFamily="matterMedium"
              backgroundColor="#333333"
              border="1px solid #6F6F6F"
              onClick={onClose}
            />
          </div>
          <div className={Styles.wrapper__deleteBtn}>
            <Button
              title="Yes, Delete."
              fontFamily="matterMedium"
              onClick={handleSubmit}
              loading={loader}
              backgroundColor="#E05F3F"
            />
          </div>
        </div>
      </AppCenterModal>
    </div>
  );
};

export default DeleteSongModal;
