import React from "react";
import { useDispatch } from "react-redux";
import MenuIcon from "../../assets/svgs/MenuIcon";
import { useAppSelector } from "../../redux/reducers/hooks";
import { setIsShowSidebarModal } from "../../redux/reducers/sidebarModal";
import AppAvater from "../appAvater";
import AppLogo from "../appLogo";
import Styles from "./AppHeader.module.scss";
const AppHeader = () => {
  const dispatch = useDispatch();
  const { userProfile } = useAppSelector((state) => state.userProfileDetails);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__logoContainer}>
        <AppLogo logoWidth="22px" titleFontSize="14px" subTitleFontSize="6px" />
      </div>
      <div className={Styles.wrapper__inputContainer}></div>
      <div className={Styles.wrapper__avaterContainer}>
        <AppAvater width="32px" height="32px" avatarUrl={userProfile?.avatar} />
        <span className={Styles.wrapper__stageName}>
          {userProfile?.stageName}
        </span>
        <span
          onClick={() => dispatch(setIsShowSidebarModal(true))}
          className={Styles.wrapper__menuIcon}
        >
          <MenuIcon />{" "}
        </span>
      </div>
    </div>
  );
};

export default AppHeader;
