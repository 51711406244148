import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/appButton";
import AuthWrapper from "../../components/authWrapper";
import TextInput from "../../components/TextInput";
import Styles from "./Login.module.scss";
import services from "../../controllers";
import useToast from "../../utilities/hooks/useToast";
import { setItemToSessionStorage } from "../../utilities/helpers/appHealper";
const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const loginValidationSchema = Yup?.object()?.shape({
    email: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    password: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
  });

  const loginFormik = useFormik({
    validationSchema: loginValidationSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values: any) => {
      setLoader(true);
      let data = {
        email: values?.email,
        password: values?.password,
      };

      const loginAccount = new services().postService("auth/login", data);
      loginAccount
        .then((res) => {
          setLoader(false);
          if (res?.status === "success") {
            setItemToSessionStorage("token", res?.data?.token);
            navigate("/dashboard");
          } else {
            toast.error(
              res?.errors?.password?.msg ||
                res?.errors?.email?.msg ||
                res?.message,
            );
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("an error occurred, try again later.");
        });
    },
  });

  return (
    <div className={Styles.wrapper}>
      <AuthWrapper>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__title}>Welcome Back!</div>
          <div className={Styles.wrapper__subtitle}>
            Kindly fill in the details below to log in to account
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={loginFormik?.values?.email}
              name={"email"}
              onChange={loginFormik.handleChange}
              placeholder="Enter your Email Address"
              error={loginFormik.submitCount > 0 && loginFormik.errors.email}
            />
          </div>
          <div style={{ marginBottom: "16px" }}>
            <TextInput
              value={loginFormik?.values?.password}
              name={"password"}
              onChange={loginFormik.handleChange}
              placeholder="Enter your Password"
              type="password"
              error={loginFormik.submitCount > 0 && loginFormik.errors.password}
            />
          </div>
          <div className={Styles.wrapper__forgotPassword}>
            <Link to="/forgot-password/email">Forgot your Password?</Link>
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Log In"
              onClick={loginFormik?.handleSubmit}
              loading={loader}
            />
          </div>
          <div className={Styles.wrapper__loginRoute}>
            Don’t have an account?{" "}
            <Link to="/create-account">Create an Account</Link>
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default Login;
