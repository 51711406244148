import React, { useEffect, useState } from "react";
import SelectSearchComp from "react-select";
import { IDropdownInput } from "./DropdownInput.types";

import "./DropdownInput.scss";

const getValue = (options: { value: string }[], checkValue: string) => {
  if (!options || !checkValue) {
    return null;
  }
  const obj = options.filter((option) => option.value === checkValue);
  return obj;
};

const DropdownInput = ({
  placeholder,
  label,
  value,
  onChange,
  name,
  error,
  options,
  required,
  disabled,
  loading,
  noOrder,
}: IDropdownInput) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const handleChange = (e: any) => {
    onChange({ target: { value: e.value, name, id: e.id } });
  };
  const valueResult = getValue(dropdownOptions, value);

  useEffect(() => {
    const datas = noOrder
      ? options
      : options
          ?.sort((a: any, b: any) => {
            // if it the label is others then it will be at the end
            if (a?.label?.toLowerCase() === "others") {
              return 1;
            }
            if (b?.label?.toLowerCase() === "others") {
              return -1;
            }

            return a?.label
              ?.substring(0, 1)
              ?.localeCompare(b?.label?.substring(0, 1));
          })
          ?.map((list: any) => {
            return list;
          });

    setDropdownOptions(datas);
  }, [options]);
  return (
    <div className={"wrapper"}>
      {label && (
        <div
          className={"wrapper__label"}
          style={{ ...(disabled && { zIndex: 10 }) }}
        >
          {label}
        </div>
      )}
      <SelectSearchComp
        placeholder={placeholder}
        onChange={handleChange}
        className={"basic-single select-drop-style"}
        id={error ? "error-select-search-comp" : "select-search-comp"}
        isLoading={loading}
        styles={{
          control: (base: any) => ({
            ...base,
            border: error ? "1px solid rgb(241, 37, 37)" : "1px solid #4D4D4D",

            // This line disable the blue border
            boxShadow: error ? "0 0 0.6rem #ff0000" : "none",
            height: "56px",
            color: "white",
            paddingTop: "0px",
            background: disabled ? "rgb(245, 245, 245)" : "transparent",
            paddingLeft: "24px",
            paddingRight: "24px",
            fontSize: "12px",
            borderRadius: "30px",
            // animation: "shake 0.2s ease-in-out 0s 2",

            // This line disable the outline border
            "&:hover": {
              boxShadow: error ? "0 0 0.6rem #ff0000" : "none",
            },
            "&:focus": {
              boxShadow: "none",
            },
          }),
          indicatorSeparator: (base: any) => ({
            ...base,
            display: "none",
          }),
          singleValue: (base: any) => ({
            ...base,
            color: "white",
            fontSize: "12px",
            fontWeight: "400",
          }),
          option: (base: any, state: any) => ({
            ...base,
            color: state?.isSelected ? "white" : "white",
            backgroundColor: state?.isSelected
              ? "rgba(224, 95, 63, .5)"
              : "rgba(37, 37, 37, 1)",
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "400",
            zIndex: 5000,

            "&:hover": {
              color: "white",
              backgroundColor: state?.isSelected
                ? "rgba(224, 95, 63, .5)"
                : "rgba(224, 95, 63, .9)",
              cursor: "pointer",
              zIndex: 5000,
            },
          }),
          menu: (base: any) => ({
            ...base,
            zIndex: 7000,
            background: "rgba(37, 37, 37, 1)",
          }),
          placeholder: (base: any) => ({
            ...base,
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "400",
            color: error ? "rgb(241, 37, 37)" : "rgba(111, 111, 111, 1)",
          }),
          dropdownIndicator: (base: any) => ({
            ...base,
            opacity: disabled ? "0" : "1",
          }),
        }}
        value={valueResult}
        options={dropdownOptions}
        required={required}
        isDisabled={disabled}
      />

      {error && <div className={"wrapper__error"}>{error}</div>}
    </div>
  );
};

export default DropdownInput;
