/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISidebarModalSlice {
  isShowSidebarModal: boolean;
}

const initialState: ISidebarModalSlice = {
  isShowSidebarModal: false,
};

export const sidebarModalSlice = createSlice({
  name: "sidebarModal",
  initialState,
  reducers: {
    setIsShowSidebarModal: (
      state: ISidebarModalSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.isShowSidebarModal = payload;
    },
  },
});

const { actions, reducer } = sidebarModalSlice;

export const { setIsShowSidebarModal } = actions;

export default reducer;
