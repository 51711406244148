import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 5.15625H18.125C18.4702 5.15625 18.75 4.87645 18.75 4.53125V2.96875C18.75 2.62355 18.4702 2.34375 18.125 2.34375H1.875C1.5298 2.34375 1.25 2.62355 1.25 2.96875V4.53125C1.25 4.87645 1.5298 5.15625 1.875 5.15625ZM1.875 11.4062H18.125C18.4702 11.4062 18.75 11.1264 18.75 10.7812V9.21875C18.75 8.87355 18.4702 8.59375 18.125 8.59375H1.875C1.5298 8.59375 1.25 8.87355 1.25 9.21875V10.7812C1.25 11.1264 1.5298 11.4062 1.875 11.4062ZM1.875 17.6562H18.125C18.4702 17.6562 18.75 17.3764 18.75 17.0312V15.4688C18.75 15.1236 18.4702 14.8438 18.125 14.8438H1.875C1.5298 14.8438 1.25 15.1236 1.25 15.4688V17.0312C1.25 17.3764 1.5298 17.6562 1.875 17.6562Z"
        fill="#E9E9E9"
      />
    </svg>
  );
};

export default MenuIcon;
