import React from "react";
import { Route, Routes } from "react-router-dom";

import AppSearchAndTabs from "../../../../components/appSearchAndTab";
import Loading from "../../../../components/Loading";
import Styles from "./BlindLight.module.scss";
const MySoundsTable = React.lazy(() => import("../MySoundsTable"));
const GeneratedAudojiTable = React.lazy(
  () => import("../GeneratedAudojiTable"),
);
const NotFound = React.lazy(() => import("../../../../components/NotFound"));
interface IBlindLight {
  title: string;
  songId: any;
}
const BlindLight = ({ title, songId }: IBlindLight) => {
  return title ? (
    <React.Suspense fallback={<Loading />}>
      <div className={Styles.wrapper}>
        <div className="row">
          <div className="col-xl-12">
            <div className={`${Styles.wrapper__title}`}>
              {title || "Blinding Light"}
            </div>
            <div className={Styles.wrapper__tabs}>
              <AppSearchAndTabs />
            </div>
            <div className={Styles.wrapper__recordPreview}>
              <Routes>
                <Route
                  index
                  element={
                    <GeneratedAudojiTable title={title} songId={songId} />
                  }
                />
                <Route path="/soundboard" element={<MySoundsTable />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          {/* <div className="col-xl-4">
          <AudojiTop />
        </div> */}
        </div>
      </div>
    </React.Suspense>
  ) : (
    <div />
  );
};

export default BlindLight;
