import React from "react";
import AnalyticsIcon from "../../../assets/svgs/AnalyticsIcon";
import LogoutIcon from "../../../assets/svgs/LogoutIcon";
import SettingsIcon from "../../../assets/svgs/SettingsIcon";
import SoundBoardIcon from "../../../assets/svgs/SoundBoardIcon";
import AppLink from "../../appLink";
import Styles from "./Sidebar.module.scss";
const Sidebar = () => {
  const sidebarDetails = [
    {
      id: 1,
      title: "Creator Studio",
      link: "/dashboard",
      activeLink: ["/dashboard", "/dashboard/soundboard"],
      icon: <SoundBoardIcon color="white" />,
      inactiveIcon: <SoundBoardIcon color="rgba(170, 170, 170, 1)" />,
    },
    {
      id: 2,
      title: "Analytics",
      link: "/dashboard/analytics",
      activeLink: ["/dashboard/analytics"],
      icon: <AnalyticsIcon color="white" />,
      inactiveIcon: <AnalyticsIcon color="rgba(170, 170, 170, 1)" />,
    },
    {
      id: 3,
      title: "Settings",
      link: "/dashboard/settings",
      activeLink: [
        "/dashboard/settings",
        "/dashboard/settings/change-password",
      ],
      icon: <SettingsIcon color="white" />,
      inactiveIcon: <SettingsIcon color="rgba(170, 170, 170, 1)" />,
    },
    {
      id: 4,
      title: "Logout",
      icon: <LogoutIcon />,
      inactiveIcon: <LogoutIcon />,
      color: "#CD0A0A",
    },
  ];
  return (
    <div className={Styles.wrapper}>
      {sidebarDetails?.map((list) => <AppLink details={list} key={list?.id} />)}
    </div>
  );
};

export default Sidebar;
