import React, { useRef } from "react";
import { useAppSelector } from "../../../redux/reducers/hooks";

import BlindLight from "./BlindLight";

import Styles from "./Home.module.scss";
import TopSounds from "./MyTopSound";
const Home = () => {
  const { musicTitle, musicId } = useAppSelector((state) => state?.songDetails);

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);

  const executeScroll = () => scrollToRef(myRef);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__stats}>
        SHOWING STATS FROM: <span>ALL TIME</span>
      </div>

      <div>
        <TopSounds
          onClickSong={() => {
            executeScroll();
          }}
        />
        {musicTitle && (
          <div ref={myRef}>
            <BlindLight title={musicTitle} songId={musicId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
