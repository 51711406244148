import React from "react";

const PrevIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7835 17.4434C15.951 17.6126 16.0449 17.841 16.0449 18.0791C16.0449 18.3172 15.951 18.5456 15.7835 18.7148C15.7012 18.7983 15.6031 18.8647 15.495 18.9099C15.3869 18.9552 15.2708 18.9785 15.1536 18.9785C15.0364 18.9785 14.9203 18.9552 14.8122 18.9099C14.7041 18.8647 14.606 18.7983 14.5238 18.7148L9.54475 13.6344C9.37748 13.4648 9.28369 13.2362 9.28369 12.998C9.28369 12.7599 9.37748 12.5313 9.54475 12.3617L14.5238 7.2813C14.606 7.19777 14.7041 7.13144 14.8122 7.08617C14.9203 7.04089 15.0364 7.01758 15.1536 7.01758C15.2708 7.01758 15.3869 7.04089 15.495 7.08617C15.6031 7.13144 15.7012 7.19777 15.7835 7.2813C15.951 7.45047 16.0449 7.67893 16.0449 7.917C16.0449 8.15507 15.951 8.38353 15.7835 8.5527L11.7002 13L15.7835 17.4434Z"
        fill="#252525"
      />
    </svg>
  );
};

export default PrevIcon;
