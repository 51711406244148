import React from "react";
import { Route, Routes } from "react-router-dom";
import AppTab from "../../../components/appTab";
import Loading from "../../../components/Loading";

import Styles from "./Settings.module.scss";
const ProfileLink = React.lazy(() => import("./ProfileLinks"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));
const NotFound = React.lazy(() => import("../../../components/NotFound"));

const Settings = () => {
  const details = [
    {
      id: 1,
      name: "Artist’s Profile Links",
      link: "/dashboard/settings",
      activeLink: ["/dashboard/settings"],
    },
    {
      id: 2,
      name: "Password Settings",
      link: "/dashboard/settings/change-password",
      activeLink: ["/dashboard/settings/change-password"],
    },
  ];
  return (
    <React.Suspense fallback={<Loading />}>
      <div className={Styles.wrapper}>
        <div className={Styles.wrapper__title}>Settings</div>
        <div className={Styles.wrapper__tabContainer}>
          <AppTab data={details} />
        </div>
        <Routes>
          <Route index element={<ProfileLink />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </React.Suspense>
  );
};

export default Settings;
