import React from "react";
import "./Loading.scss";
interface ILoading {
  height?: string;
}
const Loading = ({ height }: ILoading) => {
  return (
    <div className="load-wrapper" style={{ height }}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loading;
