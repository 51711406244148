/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IsongDetailsSlice {
  musicTitle: string;
  musicId: string;
  musicImage: string;
  artistName: string;
  spotifyLink: string;
}

const initialState: IsongDetailsSlice = {
  musicTitle: "",
  musicId: "",
  musicImage: "",
  artistName: "",
  spotifyLink: "",
};

export const songDetailsSlice = createSlice({
  name: "songDetails",
  initialState,
  reducers: {
    setMusicTitle: (
      state: IsongDetailsSlice,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.musicTitle = payload;
    },
    setMusicId: (state: IsongDetailsSlice, action: PayloadAction<string>) => {
      const { payload } = action;
      state.musicId = payload;
    },
    setMusicImage: (
      state: IsongDetailsSlice,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.musicImage = payload;
    },
    setArtistName: (
      state: IsongDetailsSlice,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.artistName = payload;
    },
    setSpotifyLink: (
      state: IsongDetailsSlice,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.spotifyLink = payload;
    },
  },
});

const { actions, reducer } = songDetailsSlice;

export const {
  setMusicTitle,
  setMusicId,
  setArtistName,
  setMusicImage,
  setSpotifyLink,
} = actions;

export default reducer;
