import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppLogo from "../appLogo";
import Styles from "./index.module.scss";
interface IAuthWrapper {
  children: any;
}
const AuthWrapper = ({ children }: IAuthWrapper) => {
  const url: any = process.env.REACT_APP_WEBSITE_URL;
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__header}>
        <div className={Styles.wrapper__linkContainer}>
          <Link to={url}>
            <AppLogo />
          </Link>
        </div>
      </div>
      <div className={Styles.wrapper__container}>
        <div className={`${Styles.wrapper__formContainer} app-wrapper-color`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
