import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/appButton";

import AuthWrapper from "../../components/authWrapper";
import CheckInput from "../../components/checkInput";
import TextInput from "../../components/TextInput";
import Styles from "./index.module.scss";
import DropdownInput from "../../components/DropdownInput";
import useToast from "../../utilities/hooks/useToast";
import services from "../../controllers";
const CreateAccount = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [countires, setCountries] = useState([]);
  const [loader, setLoader] = useState(false);
  const url: any = process.env.REACT_APP_WEBSITE_URL;

  const getCountries = () => {
    const getCountryFromServer = new services().getWithoutBaseUrlService(
      "https://dev.audoji.app/v1/general/CountryLocations",
    );

    getCountryFromServer
      .then((res) => {
        const countires = res?.data?.map((list: any) => {
          return {
            label: list?.location,
            value: list?.location,
          };
        });
        setCountries(countires || []);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCountries();
  }, []);

  const createAccountValidationSchema = Yup?.object()?.shape({
    email: Yup.string()
      .email("Field should contain a valid e-mail")
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    password: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        "Password must contain at least one special character",
      ),
    name: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    firstName: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    lastName: Yup.string()
      .required("Required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    country: Yup.string().required("Required"),

    city: Yup.string().required("Required"),
  });

  const createAccountFormik = useFormik({
    validationSchema: createAccountValidationSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      name: "",
      country: "",
      city: "",
    },
    onSubmit: async (values: any) => {
      if (!acceptTerms) {
        return toast.error("You must agree to terms and condition.");
      }

      setLoader(true);

      let data = {
        stageName: values?.name,
        email: values?.email,
        password: values?.password,
        role: "creator",
        terms_privacy: acceptTerms,
        city: values?.city,
        country: values?.country,
        firstName: values?.firstName,
        lastName: values?.lastName,
      };

      const createAccount = new services().postService("auth/createUser", data);
      createAccount
        .then((res) => {
          setLoader(false);
          if (res?.status === "success") {
            toast.success(res?.message);
            createAccountFormik.resetForm();
            navigate("/");
          } else {
            toast.error(
              res?.errors?.email?.msg ||
                res?.errors?.password?.msg ||
                res?.message,
            );
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("an error occurred, try again later.");
        });
      console.log(".....");
    },
  });

  return (
    <div className={Styles.wrapper}>
      <AuthWrapper>
        <div className={Styles.wrapper__container}>
          <div className={Styles.wrapper__title}>Create an Account!</div>
          <div className={Styles.wrapper__subtitle}>
            Kindly fill in the details below to create an account
          </div>
          <div className="row">
            <div className="col-md-6" style={{ marginBottom: "28px" }}>
              <TextInput
                value={createAccountFormik?.values?.firstName}
                name="firstName"
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your First Name"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.firstName
                }
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "28px" }}>
              <TextInput
                value={createAccountFormik?.values?.lastName}
                name="lastName"
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your Last Name"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.lastName
                }
              />
            </div>
            <div className="col-12" style={{ marginBottom: "28px" }}>
              <TextInput
                value={createAccountFormik?.values?.name}
                name="name"
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your Stage Name (Alias)"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.name
                }
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "28px" }}>
              <DropdownInput
                options={countires}
                name="country"
                placeholder="Select your Country"
                onChange={createAccountFormik?.handleChange}
                value={createAccountFormik?.values?.country}
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.country
                }
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "28px" }}>
              <TextInput
                value={createAccountFormik?.values?.city}
                name="city"
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your City"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.name
                }
              />
            </div>

            <div className="col-12" style={{ marginBottom: "28px" }}>
              <TextInput
                value={createAccountFormik?.values?.email}
                name={"email"}
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your Email Address"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.email
                }
              />
            </div>
            <div className="col-12" style={{ marginBottom: "48px" }}>
              <TextInput
                value={createAccountFormik?.values?.password}
                name={"password"}
                onChange={createAccountFormik.handleChange}
                placeholder="Enter your Password"
                type="password"
                error={
                  createAccountFormik.submitCount > 0 &&
                  createAccountFormik.errors.password
                }
              />
            </div>
          </div>
          <div className={Styles.wrapper__checkContainer}>
            <CheckInput
              isChecked={acceptTerms}
              onChange={() => {
                setAcceptTerms(!acceptTerms);
              }}
              label={
                <div className={Styles.wrapper__check}>
                  By Signing Up, I agree to Audoji’s{" "}
                  <span>
                    {" "}
                    <Link
                      to={`${url}terms-and-conditions`}
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Terms of Use{" "}
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    {" "}
                    <Link
                      to={`${url}privacy-policy`}
                      target={"_blank"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Privacy Policy.
                    </Link>
                  </span>
                </div>
              }
            />
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Create an Account"
              onClick={createAccountFormik?.handleSubmit}
              loading={loader}
            />
          </div>
          <div className={Styles.wrapper__loginRoute}>
            Already have an account? <Link to="/">Login</Link>
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default CreateAccount;
