/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IEditMusicDetailsSlice {
  musicEditDetails: any;
}

const initialState: IEditMusicDetailsSlice = {
  musicEditDetails: null,
};

export const editMusicDetailsSlice = createSlice({
  name: "editMusicDetails",
  initialState,
  reducers: {
    setMusicEditDetails: (
      state: IEditMusicDetailsSlice,
      action: PayloadAction<any>,
    ) => {
      const { payload } = action;
      state.musicEditDetails = payload;
    },
  },
});

const { actions, reducer } = editMusicDetailsSlice;

export const { setMusicEditDetails } = actions;

export default reducer;
