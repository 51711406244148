import React from "react";
import AppHeader from "../appHeader";
import Styles from "./DashboardWrapper.module.scss";
import Sidebar from "./sideBar";
interface IDashboardWrapper {
  children: any;
}
const DashboardWrapper = ({ children }: IDashboardWrapper) => {
  return (
    <div className={Styles.wrapper}>
      <AppHeader />
      <div className={Styles.wrapper__container}>
        <div className={Styles.wrapper__sidebarContainer}>
          <Sidebar />
        </div>
        <div className={Styles.wrapper__dashboardContainer}>{children}</div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
