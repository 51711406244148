/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUploadModalSlice {
  isShowUploadModal: boolean;
  isRefetchMusic: boolean;
}

const initialState: IUploadModalSlice = {
  isShowUploadModal: false,
  isRefetchMusic: false,
};

export const uploadModalSlice = createSlice({
  name: "uploadModal",
  initialState,
  reducers: {
    setIsShowUploadModal: (
      state: IUploadModalSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.isShowUploadModal = payload;
    },
    setIsRefetchMusic: (
      state: IUploadModalSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.isRefetchMusic = payload;
    },
  },
});

const { actions, reducer } = uploadModalSlice;

export const { setIsShowUploadModal, setIsRefetchMusic } = actions;

export default reducer;
