import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5312 8.53118L10.2812 13.7812C9.8125 14.2499 9 13.9218 9 13.2499V10.2499H4.75C4.33437 10.2499 4 9.91556 4 9.49993V6.49993C4 6.08431 4.33437 5.74993 4.75 5.74993H9V2.74993C9 2.08118 9.80937 1.74993 10.2812 2.21868L15.5312 7.46868C15.8219 7.76243 15.8219 8.23743 15.5312 8.53118ZM6 13.6249V12.3749C6 12.1687 5.83125 11.9999 5.625 11.9999H3C2.44688 11.9999 2 11.5531 2 10.9999V4.99993C2 4.44681 2.44688 3.99993 3 3.99993H5.625C5.83125 3.99993 6 3.83118 6 3.62493V2.37493C6 2.16868 5.83125 1.99993 5.625 1.99993H3C1.34375 1.99993 0 3.34368 0 4.99993V10.9999C0 12.6562 1.34375 13.9999 3 13.9999H5.625C5.83125 13.9999 6 13.8312 6 13.6249Z"
        fill="#CD0A0A"
      />
    </svg>
  );
};

export default LogoutIcon;
