import React from "react";
import SoundBoardIcon from "../../assets/svgs/SoundBoardIcon";
import Button from "../appButton";
import Styles from "./UploadCard.module.scss";
interface IUploadCard {
  title: string;
  subTitle?: string;
  onClick?: () => void;
}
const UploadCard = ({ title, subTitle, onClick }: IUploadCard) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__icon}>
        <SoundBoardIcon width="30px" height="30px" />
      </div>
      {title && <div className={Styles.wrapper__title}>{title}</div>}

      <div className={Styles.wrapper__subtitle}>{subTitle}</div>
      <Button
        title="Upload a Song"
        className={Styles.wrapper__btn}
        onClick={onClick}
      />
    </div>
  );
};

export default UploadCard;
