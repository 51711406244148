import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
interface IAppSkeleton {
  count: number;
  height: number;
}
const AppSkeleton = ({ count, height }: IAppSkeleton) => {
  return (
    <SkeletonTheme baseColor="silver" highlightColor="#444">
      <p>
        <Skeleton count={count} height={height} />
      </p>
    </SkeletonTheme>
  );
};

export default AppSkeleton;
